import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/src/components/Base/Analytics/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/src/components/DataDog/DataDogInit.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/src/styles/globals.css");
