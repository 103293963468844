'use client';

import { datadogRum } from '@datadog/browser-rum';

import { useEffect } from 'react';

export default function DatadogInit() {
  useEffect(() => {
    const currentEnvironment =
      window && window?.location.host === 'access-dev.atlas-sp.com'
        ? 'dev'
        : window?.location.host === 'access-uat.atlas-sp.com'
          ? 'uat'
          : window?.location.host === 'access.atlas-sp.com'
            ? 'prod'
            : 'localhost';

    if (typeof window?.DD_RUM?.getInternalContext() === 'undefined') {
      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID || '59db0b70-58a8-4ca2-b0ad-780e45aec442',
        clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || 'pub3a5ed9e5ee9249ae939f40681b80e7ae',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.com',
        service: 'aga-portal-data-dog-application',
        env: currentEnvironment,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: '1.1.7',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
    }
  });

  useEffect(() => {
    if (typeof window?.DD_RUM?.getInternalContext() !== 'undefined') {
      datadogRum.startSessionReplayRecording();
    }
  });
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
